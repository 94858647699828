import NButton from "../buttons/n-button.js";
import NCheckbox from "../inputs/n-checkbox.js";
import NMapboxGeocoder from "./n-mapbox-geocoder.js";
import NDateInput from "../inputs/n-date-input.js";
import NMapboxCreateRoutePopup from "./n-mapbox-create-route-popup.js";
import NDialog from "../n-dialog.js";


class ROUTING_PROFILES {
}

ROUTING_PROFILES.DRIVING_TRAFFIC = 'mapbox/driving-traffic'
ROUTING_PROFILES.DRIVING = 'mapbox/driving'
ROUTING_PROFILES.CYCLING = 'mapbox/cycling'
ROUTING_PROFILES.WALKING = 'mapbox/walking'

const ZOOM_LEVEL_DEFAULT = 5
const ZOOM_LEVEL_CLOSE = 8

class RADIUS {
}

RADIUS.SMALL = 20
RADIUS.MEDIUM = 30
RADIUS.LARGE = 40

class THRESHOLD {
}

THRESHOLD.FIRST = 10
THRESHOLD.SECOND = 50

class EVENTS {
}

EVENTS.SELECT_LOCATION = 'on-select-location'
EVENTS.ADD_LOCATION = 'on-add-location'
EVENTS.ON_SEARCH = 'on-search'
EVENTS.STARTMARKER_DRAG = 'start-drag'
EVENTS.ENDMARKER_DRAG = 'end-drag'
EVENTS.AUDIT_CHANGE = 'on-audit-change'

const MAPBOX_CLUSTER_PAINT_STYLE = {
    'circle-color':
        [
            'step',
            ['get', 'point_count'],
            '#19A08C',
            THRESHOLD.FIRST,
            '#28C882',
            THRESHOLD.SECOND,
            '#A0FFBE'
        ],
    'circle-radius': [
        'step',
        ['get', 'point_count'],
        RADIUS.SMALL,
        THRESHOLD.FIRST,
        RADIUS.MEDIUM,
        THRESHOLD.SECOND,
        RADIUS.LARGE,
    ],
}

const MAPBOX_CLUSTER_TEXT_LAYOUT = {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 16
}
const MAPBOX_CLUSTER_TEXT_PAINT = {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 1
}

export default {
    inject: ['toaster', 'selectedLocations'],
    props: {
        isLoading: Boolean,
        placesData: Object,
        isDisabled: {
            default:
                false,
            type:
            Boolean,
        },
        startWaypoint: Object,
        endWaypoint: Object,
        center: Array,
        routeGeoJson: Object,
    },
    emits: Object.values(EVENTS),
    components:
        {
            'n-dialog': NDialog,
            'n-date-input': NDateInput,
            'n-mapbox-create-route-popup': NMapboxCreateRoutePopup,
            'n-button': NButton,
            'n-checkbox': NCheckbox,
            'n-mapbox-geocoder': NMapboxGeocoder,
        }
    ,
    watch: {
        startWaypoint: {
            handler(waypoint) {
                const {center} = waypoint

                if (this.map && center) {
                    this.startMarker.setLngLat(center).addTo(this.map);
                }
            },
            deep: true
        },
        selectedLocations() {
            let difference = Vue.toRaw(this.selectedMarkers).filter(oldLoc => {
                    return !this.selectedLocations.some(newLoc => {
                        return newLoc?.id === oldLoc?.id
                    })
                }
            );
            if (difference.length > 0) {
                const feature = difference[0]
                const auditsToRemove = JSON.parse(difference[0].properties.audits)
                this.selectedAudits = this.selectedAudits.filter(pk => {
                        return !auditsToRemove.some(a => a.pk === pk)
                    }
                );
                this.$emit(EVENTS.AUDIT_CHANGE, this.selectedAudits)
                this.toggleMarker(feature)
            }
        },
        endWaypoint: {
            handler(waypoint) {
                const {center} = waypoint
                if (this.map && center) {
                    this.endMarker.setLngLat(center).addTo(this.map);
                }
            },
            deep: true
        },
        routeGeoJson: {
            handler(routeGeoJson) {
                this.setRoute();
            },
            deep: true
        },
        isLoading: {
            handler(newValue) {
                if (newValue) {
                    this.runLoadingAnimation = newValue
                }
            }
        }
    }
    ,
    data() {
        return {
            map: null,
            directions: null,
            selectedMarkers: [],
            routeData: {},
            zoom: ZOOM_LEVEL_DEFAULT,
            stops: [],
            popup: null,
            mapboxSearchField: null,
            searchMarker: null,
            runLoadingAnimation: false,
            startMarker: null,
            endMarker: null,

            geocoderFrom: null,
            geocoderTo: null,
            filters: {
                'stopType': [],
                'dueDate': {
                    'fromDate': null,
                    'toDate': null,
                },
            },
            fromDateValue: null,
            toDateValue: null,
            showAuditsDialog: false,
            selectedAudits: [],
            previouslySelectedAudits: [],
            allCurrentFacilityAudits: [],
            tempSelectedAudits: [],
            currentFacility: null,
        };
    }
    ,
    methods: {
        setupMap(center) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: center,
                    zoom: this.zoom
                });

                this.startMarker = new mapboxgl.Marker({
                    color: '#0079c5',
                    draggable: true
                })
                this.endMarker = new mapboxgl.Marker({
                    color: '#15d400', draggable: true
                })

                this.map.once('load', () => {
                    setTimeout(() => {

                        this.popup = new mapboxgl.Popup({
                            className: 'n-map-popup',
                            closeButton: false,
                        });
                        this.loadPlaces()
                        this.map.resize()
                        this.addMapEvents()

                        setTimeout(() => {
                            this.runLoadingAnimation = false
                        }, 200)
                    }, 200)
                })

            }
        },
        addMarkerLayers() {
            // Default icon layer

            this.map.addLayer({
                id: `${this.placesData.layerID}-default`,
                type: 'symbol',
                source: this.placesData.layerID,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': [
                        'match',
                        ['get', 'stopType'],
                        'E-cal', MAP_MARKER[NOT_SELECTED_ECAL].name,
                        'Sermi', MAP_MARKER[NOT_SELECTED_SERMI].name,
                        /* default */ 'default-icon', // Optional: specify a default icon
                    ],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
                paint: {
                    // Optional: Add opacity to verify icons are visible
                    'icon-opacity': 0.8
                }
            });

            // Selected icon layer
            this.map.addLayer({
                id: `${this.placesData.layerID}-selected`,
                type: 'symbol',
                source: this.placesData.layerID,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': [
                        'match',
                        ['get', 'stopType'],
                        'E-cal', MAP_MARKER[SELECTED_ECAL].name,
                        'Sermi', MAP_MARKER[SELECTED_SERMI].name,
                        /* default */ 'default-icon', // Optional: specify a default icon
                    ],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
                paint: {
                    'icon-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'selected'], false],
                        1,  // Fully visible if selected
                        0   // Hidden if not selected
                    ]
                }
            });
        },
        loadPlaces() {
            this.loadImages().then(() => {

                this.map.addSource(this.placesData.layerID, {
                    type: 'geojson',
                    data: this.placesData.data,
                });
                this.addMarkerLayers();
            })
        },
        loadImages() {
            const promises = MAP_MARKER.map(image => {
                return new Promise((resolve, reject) => {
                    this.map.loadImage(image.url, (error, img) => {
                        if (error) {
                            reject(error);
                            return
                        }
                        this.map.addImage(image.name, img);
                        resolve();
                    });
                });
            });
            return Promise.all(promises);
        },
        canClick() {
            if (!this.startWaypoint) {
                return false
            }
            if (!this.endWaypoint) {
                return false
            }
            return true
        },
        selectAudit(pk) {
            const targetArray = this.showAuditsDialog ? this.tempSelectedAudits : this.selectedAudits;
            const index = targetArray.indexOf(pk);
            if (index > -1) {
                // Audit already selected, remove it
                targetArray.splice(index, 1);
                return
            }
            // Not selected, add it
            targetArray.push(pk);
        },
        cancelSelectedAudits() {

            this.previouslySelectedAudits = []
            this.showAuditsDialog = false
        },
        confirmSelectedAudits(e) {
            if (e.close_cb) {
                e.close_cb()
            }

            const toBeRemovedPks = this.allCurrentFacilityAudits
                .filter(audit => !this.tempSelectedAudits.includes(audit?.pk))
                .map(audit => audit?.pk);

            this.selectedAudits = [
                ...this.selectedAudits.filter(auditPk => !toBeRemovedPks.includes(auditPk)),
                ...this.tempSelectedAudits.filter(auditPk => !this.selectedAudits.includes(auditPk))
            ]


            //Only want to add marker if doesnt already exist within the selected markers
            const existedBeforeDialogOpen = this.previouslySelectedAudits.some(auditPk => this.tempSelectedAudits.includes(auditPk))

            if (this.tempSelectedAudits?.length > 0 && !existedBeforeDialogOpen) {
                this.addMarker(this.currentFacility)
            }

            if (this.tempSelectedAudits?.length === 0) {
                this.removeMarker(this.currentFacility)
            }
            this.$emit(EVENTS.AUDIT_CHANGE, this.selectedAudits)

            this.showAuditsDialog = false
            this.allCurrentFacilityAudits = []
            this.tempSelectedAudits = []
            this.currentFacility = null
        },
        addMapEvents() {
            this.map.on('click', `${this.placesData.layerID}-default`, (e) => {

                if (!this.canClick()) {
                    alert('Must select start and stop')
                    return
                }

                const feature = e.features[0]; // Get the clicked feature


                if (!feature) {
                    return
                }

                this.previouslySelectedAudits = [...this.selectedAudits]

                const audits = JSON.parse(feature.properties.audits)

                if (audits?.length > 1) {
                    // Initialize tempSelectedAudits as the currently selected audits that belong to this facility
                    this.tempSelectedAudits = this.selectedAudits.filter(pk =>
                        audits.some(a => a.pk === pk)
                    );
                    this.showAuditsDialog = true
                    this.allCurrentFacilityAudits = audits
                    this.currentFacility = feature
                    return
                }

                const audit = audits[0]
                this.toggleMarker(feature)
                this.selectAudit(audit.pk)
            });

            this.startMarker.on('dragend', (e) => {
                const newCenter = this.startMarker.getLngLat();
                this.$emit(EVENTS.STARTMARKER_DRAG, [newCenter.lng, newCenter.lat])
            });

            this.endMarker.on('dragend', () => {
                const newCenter = this.endMarker.getLngLat();
                this.$emit(EVENTS.ENDMARKER_DRAG, [newCenter.lng, newCenter.lat])
            });

            this.map.on('mouseenter', `${this.placesData.layerID}-default`, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });
            this.map.on('touchstart', `${this.placesData.layerID}-default`, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });

            this.map.on('mouseleave', `${this.placesData.layerID}-default`, (e) => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
            this.map.on('touchend', `${this.placesData.layerID}-default`, () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        },
        addMarker(place) {
            if (!place) {
                return
            }
            let newLocations = [...this.selectedLocations]

            // Add the place if it's not already in selectedMarkers
            newLocations.push(place);
            this.map.setFeatureState(
                {source: this.placesData.layerID, id: place.id},
                {selected: true}
            );
            this.selectedMarkers = [...newLocations]
            this.$emit(EVENTS.SELECT_LOCATION, newLocations);
        },
        removeMarker(place) {
            if (!place) {
                return
            }
            let newLocations = [...this.selectedLocations]

            if (this.routeGeoJson) {
                const entries = Object.keys(this.routeGeoJson).length;
                if (entries <= 2) {
                    this.toaster.addMessage("Need at least 2 locations, can't remove location");
                    return;
                }
            }
            // Remove the place by filtering it out of the selectedMarkers array
            newLocations = this.selectedLocations.filter(marker => marker?.id !== place?.id);

            this.map.setFeatureState(
                {source: this.placesData.layerID, id: place.id},
                {selected: false}
            );
            this.selectedMarkers = [...newLocations]
            this.$emit(EVENTS.SELECT_LOCATION, newLocations);
        },
        toggleMarker(place) {
            if (!place) {
                return
            }
            // Convert the selectedMarkers array into a Set of ids for quick look-up
            const selectedMarkerIds = new Set(this.selectedMarkers.map(marker => marker.id));
            if (selectedMarkerIds.has(place.id)) {
                this.removeMarker(place)
                return;
            }
            this.addMarker(place)

        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX];
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY;
            }
        },
        setRoute() {
            // if the route already exists on the map, we'll reset it using setData
            if (this.map.getSource('route') && this.routeGeoJson) {
                this.map.getSource('route').setData(this.routeGeoJson);
                return
            }
            // otherwise, we'll make a new request
            this.map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: this.routeGeoJson
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#3887be',
                    'line-width': 5,
                    'line-opacity': 0.75
                }
            });
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'));
                }
            });
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            const lng = e.lngLat.lng;

            const coordinates = feature.geometry.coordinates.slice();

            this.calculateCoordinates(coordinates, lng);

            const props = feature.properties;
            const popupContent = document.createElement('div');

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxCreateRoutePopup, {...props});
                }
            });

            tempApp.component('n-mapbox-create-route-popup', NMapboxCreateRoutePopup);
            tempApp.config.compilerOptions.delimiters = ["[[[", "]]]"]
            tempApp.mount(popupContent);

            this.popup
                .setLngLat(coordinates)
                .setOffset([0, -20])
                .setDOMContent(popupContent)
                .addTo(this.map)
        },
        filterIn(isChecked, propertyValue) {
            const property = 'stopType';

            // Update stopType filter
            this.filters[property] = isChecked
                ? [...this.filters[property], propertyValue]
                : this.filters[property].filter(type => type !== propertyValue);

            // Reapply the composite filter
            this.applyFilters();
        },
        updateFromDate(value) {
            this.filters.dueDate.fromDate = value;
            this.applyFilters();
        },
        updateToDate(value) {
            this.filters.dueDate.toDate = value;
            this.applyFilters();
        },
        getLocationsByDateFilter() {
            const resultKeys = new Set(); // Using a Set to ensure no duplicate keys

            const fromDateObj = this.filters.dueDate.fromDate ? new Date(this.filters.dueDate.fromDate) : null;
            const toDateObj = this.filters.dueDate.toDate ? new Date(this.filters.dueDate.toDate) : null;
            const dateFilterLocations = this.placesData.data.dateFilterLocations;
            for (const [key, dates] of Object.entries(dateFilterLocations)) {
                for (const dateStr of dates) {
                    const dateObj = new Date(dateStr);

                    // Check if date satisfies the "from" and "to" conditions
                    const isAfterFromDate = fromDateObj ? dateObj >= fromDateObj : true;
                    const isBeforeToDate = toDateObj ? dateObj <= toDateObj : true;

                    if (isAfterFromDate && isBeforeToDate) {
                        resultKeys.add(key); // Add key if date is within range
                        break; // Stop checking further dates for this key
                    }
                }
            }

            return Array.from(resultKeys);
        },
        applyFilters() {
            const filterConditions = ['all'];

            if (this.filters.stopType.length > 0) {
                filterConditions.push(['in', ['get', 'stopType'], ['literal', [...this.filters.stopType]]]);
            }


            if (this.filters.dueDate.fromDate || this.filters.dueDate.toDate) {
                const filteredIds = this.getLocationsByDateFilter();
                // This will include only those features whose 'id' property is in filteredIds.
                filterConditions.push(['in',['id'], filteredIds.join(',')]);
            }


            const compositeFilter = filterConditions.length > 1 ? filterConditions : null;
            this.map.setFilter(`${this.placesData.layerID}-default`, compositeFilter);
            this.map.setFilter(`${this.placesData.layerID}-selected`, compositeFilter);
        }
    },
    mounted() {
        this.getUserLocation()
            .then(position => {
                const userCenter = [position.coords.longitude, position.coords.latitude];
                this.setupMap(userCenter);
            })
            .catch(error => {
                console.error('Error getting user location:', error);
                //TODO
                const swedenCenter = [18.643501, 60.128161];
                this.setupMap(swedenCenter);
            });
    },
    template: `

    <div class="n-flex n-col gap-m" style="padding-top: 16px">
        <div class="n-flex gap-xs">
            <n-date-input range @change-from="updateFromDate" @change-to="updateToDate"></n-date-input>
            <n-checkbox @change="filterIn($event, 'E-cal')" label="E-cal"></n-checkbox>
            <n-checkbox @change="filterIn($event, 'Sermi')" label="Sermi"></n-checkbox>
        </div>
        
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div v-if="runLoadingAnimation" class="n-skeleton">
            <span class="n-loader" ></span>
            </div>
        </div>
        <n-dialog :is-open="showAuditsDialog"
                  @confirm="confirmSelectedAudits($event)"
                  @cancel="cancelSelectedAudits"
        >
            <h2>Select audit</h2>
            <p>Select one or more audits to add to your route</p>

            <div class="n-flex gap-m padding-s" style="border: 1px solid #e1edff; border-radius:4px;" v-for="audit in allCurrentFacilityAudits" :key="audit.pk" >
                <input :value="audit.pk" v-model="tempSelectedAudits" type="checkbox" :id="'audit-checkbox-' + audit.pk" :name="'audit-checkbox-' + audit.pk">
                <label :for="'audit-checkbox-' + audit.pk"  class="n-flex n-col gap-xs padding-s" style="cursor: pointer">
                    <div class="n-flex gap-s" style="pointer-events: none;">
                        <label style="font-weight: bold" class="n-clamp-text">Audit targets:</label> 
                        <p> [[[audit.audit_target_count]]]</p>
                    </div>
                    <div class="n-flex gap-s"  style="pointer-events: none;">
                        <label style="font-weight: bold" class="n-clamp-text">Deadline Date:</label> 
                        <p> [[[audit.deadline_date]]]</p>
                    </div>
                </label>
            </div>
        </n-dialog>
    </div>
    
    `,
}
;
