import NMapboxPopup from "./n-mapbox-popup.js";
import NButton from "../buttons/n-button.js";

class EVENTS {
}

EVENTS.MAP_LOADED = 'on-map-loaded'
EVENTS.SAVE_FACILITY_LOCATION = 'on-save-facility-location'

const ZOOM_LEVEL_DEFAULT = 15

export default {
    inject: ['toaster'],
    props: {
        isLoading: Boolean,
        placesData: Object,
        center: Array,
        canDrag: Boolean,
    },
    emits: Object.values(EVENTS),
    components: {
        'n-mapbox-popup': NMapboxPopup,
        'n-button': NButton,
    },
    watch: {
        center: {
            handler(newValue) {
                if (this.map) {
                    this.loadPlaces();
                    this.map.resize()

                    this.map.jumpTo({center: this.center})
                }
            },
            deep: true,
        },
        isLoading: {
            handler(newValue) {
                if (newValue) {
                    this.runLoadingAnimation = newValue;
                }
            }
        },
    },
    data() {
        return {
            map: null,
            zoom: ZOOM_LEVEL_DEFAULT,
            popup: null,
            runLoadingAnimation: false,

            // We'll store the draggable marker and popup references
            draggableMarker: null,
            draggableMarkerPopup: null,
            newAddressObj: {
                center: [],
                place: null,
                address: null,
                postalCode: null,
                postalCity: null,
            }
        };
    },
    methods: {
        setupMap(mapCenter) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    zoom: this.zoom
                });

                this.map.once('load', () => {
                    this.popup = new mapboxgl.Popup({
                        className: 'n-map-popup'
                    });
                    this.map.resize()

                    this.$emit(EVENTS.MAP_LOADED,)
                });
            }
        },

        // -------------------------------------
        // Draggable Marker Setup
        // -------------------------------------
        initDraggableMarker() {
            console.log("initDrag")
            console.log("initDrag", this.center)
            // If there's already a draggable marker, remove it first
            this.removeDraggableMarker();

            // Create a new marker at this.center or fallback to any default coordinate
            this.draggableMarker = new mapboxgl.Marker({draggable: true})
                .setLngLat(this.center || [18.643501, 60.128161]) // fallback default
                .addTo(this.map);

            this.map.jumpTo({center: this.center})

            // On drag end, we show a popup with location + address
            this.draggableMarker.on("dragend", this.onDraggableMarkerDragEnd);
        },

        removeDraggableMarker() {
            if (this.draggableMarker) {
                this.draggableMarker.remove();
                this.draggableMarker = null;
            }
            if (this.draggableMarkerPopup) {
                this.draggableMarkerPopup.remove();
                this.draggableMarkerPopup = null;
            }
        },
        onDraggableMarkerDragEnd() {
            const lngLat = this.draggableMarker.getLngLat();
            // Optionally fetch address from Mapbox Geocoding (or your own service)
            // Pass the lat/lng to get the address. This step is optional.
            this.fetchAddressFromCoordinates(lngLat.lng, lngLat.lat)
                .then(() => {
                    this.showDraggableMarkerPopup(lngLat.lng, lngLat.lat);
                })
                .catch((e) => {
                    console.warn("Could not fetch address:", e);
                    this.showDraggableMarkerPopup(lngLat.lng, lngLat.lat, "");
                });
        },

        async fetchAddressFromCoordinates(lng, lat) {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${this.$root.MAPBOX_API}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Failed to fetch address from Mapbox");
            }

            const data = await response.json();
            if (data.features && data.features.length > 0) {
                this.newAddressObj['place'] = data.features[0].place_name
                this.newAddressObj['center'] = [lng, lat]
                this.newAddressObj['address'] = `${data.features[0].text} ${data.features[0].address}`
                this.newAddressObj['postalCode'] = data.features[0].context[0].text
                this.newAddressObj['postalCity'] = data.features[0].context[1].text
                return true;
            }
            return "Unknown address";
        },

        showDraggableMarkerPopup(lng, lat) {
            // Remove any existing popup first
            if (this.draggableMarkerPopup) {
                this.draggableMarkerPopup.remove();
            }

            // Create container for popup content
            const popupContent = document.createElement('div');
            popupContent.innerHTML = `
        <div class="n-flex gap-m n-col">
            <div class="n-flex n-col gap-xs">
                
                <strong>${this.newAddressObj.place}</strong>
                <p>Lat: <strong>${lat.toFixed(5)}</strong>, Lng: <strong>${lng.toFixed(5)}</strong></p>
                <p>Address: <strong>${this.newAddressObj.address || 'Not available'}</strong></p>
                <p>Postal code: <strong>${this.newAddressObj.postalCode || 'Not available'}</strong></p>
                <p>Postal city: <strong>${this.newAddressObj.postalCity || 'Not available'}</strong></p>
            </div>
          <button class="n-button  small primary outlined" id="saveMarkerBtn">
            Save Marker Location
          </button>
        </div>
      `;

            // Create and add popup to the map
            this.draggableMarkerPopup = new mapboxgl.Popup({offset: 25})
                .setLngLat([lng, lat])
                .setDOMContent(popupContent)
                .addTo(this.map);

            // Listen for Save click
            popupContent.querySelector("#saveMarkerBtn").addEventListener("click", () => {
                this.$emit(EVENTS.SAVE_FACILITY_LOCATION, this.newAddressObj)
                this.stopDrag()
                // Optionally close the popup after saving:
                if (this.draggableMarkerPopup) {
                    this.draggableMarkerPopup.remove();
                }
            });
        },

        // -------------------------------------
        // Loading Places
        // -------------------------------------
        loadPlaces() {
            this.loadImages().then(() => {
                // Add a geojson source
                if (!this.map.getSource(this.placesData.layerID)) {
                    this.map.addSource(this.placesData.layerID, {
                        type: 'geojson',
                        data: this.placesData.data,
                    });
                } else {
                    // Update existing source data
                    this.map.getSource(this.placesData.layerID).setData(this.placesData.data);
                }
                this.addMarkerLayers();
            });
        },
        loadImages() {
            return new Promise((resolve, reject) => {
                this.map.loadImage(FACILITY_MARKER_SELECTED.url, (error, img) => {
                    if (error) {
                        reject(error);
                        return;
                    }
                    if (!this.map.hasImage(FACILITY_MARKER_SELECTED.name)) {
                        this.map.addImage(FACILITY_MARKER_SELECTED.name, img);
                    }
                    resolve();
                });
            });
        },
        addMarkerLayers() {
            if (!this.map.getLayer(this.placesData.layerID)) {
                this.map.addLayer({
                    id: this.placesData.layerID,
                    type: 'symbol',
                    source: this.placesData.layerID,
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true
                    },
                });
            }
        },
        startDrag() {
            if (this.canDrag) {
                this.initDraggableMarker();
            }
        },
        stopDrag() {
            if (this.canDrag) {
                this.removeDraggableMarker();
            }
        }
    },

    mounted() {
        mapboxgl.accessToken = this.$root.MAPBOX_API;
        this.setupMap(this.center);
    },
    template: `

    <div class="n-flex n-col gap-m" style=" flex: 1; min-height:300px; min-width:300px">
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div class="n-flex gap-xs" style="position:absolute; top:4px; left: 4px; z-index: 1000">
                <n-button  :disabled="!canDrag" tiny icon-right="fa-sharp fa-solid fa-road" text="Change location" @click="startDrag()"> </n-button>
            </div>
            <div v-if="runLoadingAnimation" class="n-skeleton">
            <span class="n-loader" ></span>
            </div>
        </div>
    </div>
    `,
}
;
