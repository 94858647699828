import NTextInput from "../inputs/n-text-input.js";

export default {
    components: {
        'n-text-input': NTextInput,
    },
    watch: {
        value(newValues) {
            this.search.search(newValues);
        },
    },
    props: {
        geocoderId: String,
        label: String,
        modelValue: String,
    },
    data() {
        return {
            geocoder: null,
            value: ''
        };
    },
    methods: {},
    created() {
    },
    mounted() {

        mapboxgl.accessToken = this.$root.MAPBOX_API;
        this.geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            placeholder: 'Rechercher'
        });
        document.getElementById('geocoder').appendChild(this.geocoder.onAdd());

        this.geocoder.on('results', function (results) {
            console.log(results);
        })
        // this.search = new MapboxSearchBox()
        // console.log(this.search)
        // this.search.accessToken = this.$root.MAPBOX_API
        // this.search.addEventListener('retrieve', (e) => {
        //     console.log(e)
        //     const feature = e.detail;
        //     console.log(feature) // geojson object representing the selected item
        // });
    }
    ,
    template: `
<div id='geocoder' class='geocoder'></div>
<!--            <n-text-input v-model="value" label="label" ></n-text-input>-->
            `,
}
;