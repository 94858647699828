import NMapboxPopup from "./n-mapbox-popup.js";
import NButton from "../buttons/n-button.js";

class EVENTS {
}

EVENTS.ON_SEARCH = 'on-search'
EVENTS.ON_CREATED = 'on-created'
EVENTS.SELECT_LOCATION = 'on-select-location'
EVENTS.MAP_LOADED = 'on-map-loaded'

const ZOOM_LEVEL_DEFAULT = 15

export default {
    inject: ['toaster'],
    props: {
        isLoading: Boolean,
        placesData: Object,
        center: Array,
        canDrag: Boolean,
    },
    emits: Object.values(EVENTS),
    components: {
        'n-mapbox-popup': NMapboxPopup,
        'n-button': NButton,
    },
    watch: {
        center: {
            handler(newValue) {
                if (this.map) {
                    this.addMarker()
                    this.loadPlaces();
                    if (this.canDrag) {
                        this.addControls()
                    }

                    this.map.resize()

                    this.map.jumpTo({center: this.center})
                }
            },
            deep: true,
        },
        isLoading: {
            handler(newValue) {
                if (newValue) {
                    this.runLoadingAnimation = newValue;
                }
            }
        },
    },
    data() {
        return {
            map: null,
            zoom: ZOOM_LEVEL_DEFAULT,
            popup: null,
            runLoadingAnimation: false,
            mapboxSearchField: null,
            searchMarker: null,
            startLocation: null,

            newAddressObj: {
                center: [],
                place: null,
                address: null,
                postalCode: null,
                postalCity: null,
            }
        };
    },
    methods: {
        setupMap(mapCenter) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    zoom: this.zoom
                });

                this.map.once('load', () => {
                    this.popup = new mapboxgl.Popup({
                        className: 'n-map-popup'
                    });
                    this.map.resize()
                    this.$emit(EVENTS.MAP_LOADED,)
                });
            }
        },
        addControls() {
            this.mapboxSearchField = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
            })
            this.map.addControl(this.mapboxSearchField);
            this.map.addControl(new mapboxgl.NavigationControl());
            this.map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true,
                showUserHeading: true
            }));

            this.addControlEvents()
        },
        addControlEvents() {
            this.mapboxSearchField.on('result', (e) => {
                this.searchMarker.setLngLat(e.result.center).addTo(this.map);
                this.searchMarker.getElement().style.backgroundImage = `url(${FACILITY_MARKER_UNSET.url})`

                const lngLat = this.searchMarker.getLngLat();
                this.fetchAddressFromCoordinates(lngLat.lng, lngLat.lat)
                    .then(() => {
                        this.$emit(EVENTS.ON_SEARCH, this.newAddressObj)
                    })
                this.map.jumpTo({center: [lngLat.lng, lngLat.lat]})

            });
            this.searchMarker.on('dragend', (e) => {
                this.searchMarker.getElement().style.backgroundImage = `url(${FACILITY_MARKER_UNSET.url})`
                const lngLat = this.searchMarker.getLngLat();
                this.fetchAddressFromCoordinates(lngLat.lng, lngLat.lat)
                    .then(() => {
                        this.$emit(EVENTS.SELECT_LOCATION, this.newAddressObj)
                    })

            });
        },
        addMarker() {
            const el = document.createElement('div');
            el.className = FACILITY_MARKER_SET.Name;
            el.style.backgroundImage = `url(${FACILITY_MARKER_SET.url})`
            el.style.width = '47px';
            el.style.height = '67.5px';

            this.searchMarker = new mapboxgl.Marker(el, {
                draggable: this.canDrag
            })
        },
        async fetchAddressFromCoordinates(lng, lat) {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${this.$root.MAPBOX_API}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Failed to fetch address from Mapbox");
            }

            const data = await response.json();
            if (data.features && data.features.length > 0) {
                this.newAddressObj['place'] = data.features[0].place_name
                this.newAddressObj['center'] = [lng, lat]
                this.newAddressObj['address'] = `${data.features[0].text} ${data.features[0].address}`
                this.newAddressObj['postalCode'] = data.features[0].context[0].text
                this.newAddressObj['postalCity'] = data.features[0].context[1].text
                return true;
            }
            return "Unknown address";
        },
        // -------------------------------------
        // Loading Places
        // -------------------------------------
        loadPlaces() {
            if (this.placesData.data.features.length > 1) {
                console.error("Facility map only handles one entry.")
                console.error("Entries: ", this.placesData.data.features.length)
                return
            }
            //should only be one
            const feature = this.placesData.data.features[0]
            this.startLocation = feature.geometry.coordinates
            this.searchMarker.setLngLat(this.startLocation).addTo(this.map)
        },
        resetMarkerCoordinates() {
            this.searchMarker.getElement().style.backgroundImage = `url(${FACILITY_MARKER_SET.url})`
            this.searchMarker.setLngLat(this.startLocation)
            this.map.jumpTo({center: this.startLocation})
        },
        addMarkerLayers() {
            if (!this.map.getLayer(this.placesData.layerID)) {
                this.map.addLayer({
                    id: this.placesData.layerID,
                    type: 'symbol',
                    source: this.placesData.layerID,
                    filter: ['!', ['has', 'point_count']],
                    layout: {
                        'icon-image': ['get', 'icon'],
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-ignore-placement': true
                    },
                });
            }
        },
    },

    mounted() {
        mapboxgl.accessToken = this.$root.MAPBOX_API;
        this.setupMap(this.center);
    },
    template: `

    <div class="n-flex n-col gap-m" style=" flex: 1; min-height:300px; min-width:300px">
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div v-if="runLoadingAnimation" class="n-skeleton">
            <span class="n-loader" ></span>
            </div>
        </div>
    </div>
    `,
}
;
