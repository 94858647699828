import NMapboxPopup from "./n-mapbox-popup.js";
import NMapboxTextfieldAutofill from "./n-mapbox-textfield-autofill.js";
import NMapboxSearch from "./n-mapbox-search.js";
import MapboxRoutePlanner from "./mapbox-route-planner.js";
import NMapboxGeocoder from "./n-mapbox-geocoder.js";
import NMapboxFacility from "./n-mapbox-facility.js";
import NMapboxRoute from "./n-mapbox-route.js";
import NMapboxCreateRoutePopup from "./n-mapbox-create-route-popup.js";


export default {
    'n-mapbox-popup': NMapboxPopup,
    'n-mapbox-textfield-autofill': NMapboxTextfieldAutofill,
    'n-mapbox-search': NMapboxSearch,
    'mapbox-route-planner': MapboxRoutePlanner,
    'n-mapbox-geocoder': NMapboxGeocoder,
    'n-mapbox-facility': NMapboxFacility,
    'n-mapbox-route': NMapboxRoute,
    'n-mapbox-create-route-popup': NMapboxCreateRoutePopup,
};
