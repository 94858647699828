export default {
    props: {
        title: String,
        address: String,
        employeeCount: Number,
    },
    data() {
        return {
            employeesText: gettext('Employees'),
        };
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    template: `
    <span>
        <div class="n-flex gap-xs">
            <p class="n-clamp-text" style="width:fit-content" :title="title"><strong> [[[ title ]]]</strong></p>
        </div>
        <div class="n-flex gap-xs">
            <i class="fas fa-location-dot" ></i>
            <p class="n-clamp-text" style="width:fit-content" :title="address">: [[[ address ]]] </p>
        </div>
        <div class="n-flex gap-xs">
             <i class="fas fa-users" ></i>
             <p  class="n-clamp-text"  style="width:fit-content" :title="employeeCount" >: [[[ employeeCount ]]] </p>
        </div>
    </span>
    `,
};