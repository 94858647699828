const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
}

export default {
    props: {
        // Norse vue props
        name: String,
        initial: String,
        choices: Array,

        // Component props
        radioGroupName: String,
        modelValue: String,
        iconClass: String,
    },
    emits: Object.values(EVENTS),
    data() {
        return {
            value: null,
            notApplicableText: gettext('Not applicable')
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
        },
        getContainerClass(choice) {
            let containerClass = 'choice-option'
            if (choice.notApplicable) {
                containerClass += ' not-applicable'
                return containerClass
            }

            if (this.selectedChoice === choice.value) {
                containerClass += ' selected'

            }
            return containerClass
        }
    },
    created() {
        if (this.initial) {
            this.propagateValue(this.initial)
        }
    },
    mounted() {

    },
    template: `
    <div class="n-flex n-col gap-xs" >
        <h3>Cars</h3>
        <div
          v-for="(choice, index) in choices"
          :key="index"
          :class="getContainerClass(choice)"
          :style="{ borderColor: choice.color, color: choice.notApplicable ? '#8c8c8c' : choice.color }"
          @click="!choice.notApplicable && propagateValue(choice.value)"
        >
          <i :class="iconClass"></i>
          <span class="choice-label">[[[ choice.text ]]]</span>
          <span v-if="choice.notApplicable" class="choice-not-applicable">[[[notApplicableText]]]</span>
          <input 
            v-model="modelValue"
            :value="choice.value"
            :name="name"
            type="radio"
            class="radio-input"
          />
        </div>
    </div>
    `,
}

